import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

function spotifyUserPlayingTrackData(jsonData) {
    if (jsonData) {
      if (jsonData.item) {
        return <li key={jsonData.item.id}>
                <img src={jsonData.item.album.images['0'].url} 
                      width='100' height='100' alt='yeah' />
                {jsonData.item.name} - {jsonData.item.artists['0'].name}</li>;
      }
      if (jsonData.currently_playing_type === "ad") {
        return <li key="ad">An ad is playing</li>;
      }
      if (jsonData.currently_playing_type === "episode") {
        return <li key="ad">A podcast episode is playing</li>;
      }
    } else {
      return <li key="yo"> Sorry, nothing is playing!</li>
    }
}

function GetSpotifyUserPlayingTrack(perspective) {
  const [loading, setLoading] = useState(true);
  const [localFriend, setLocalFriend] = useState(Cookies.get('currentFriendSub'));
  const [userPlayingTrackData, setUserPlayingTrackData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-spotify-user-playing-track' +
          ((perspective === 'CurrentUser') ? '' : '?sub=' + localFriend ), {
          headers: {
            "Authorization": "Bearer " + Cookies.get('apiToken')
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setUserPlayingTrackData(spotifyUserPlayingTrackData(jsonData))
      } catch (error) {
        setUserPlayingTrackData('Error fetching Spotify user playing track:', error)
        console.error('Error fetching Spotify user playing track:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000); // Fetch every 60 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount

  }, [perspective, localFriend]);

  // Update friend with every render when its value has changed.
  const friendCookie = Cookies.get("currentFriendSub");
  if (localFriend !== friendCookie ) {
    setLocalFriend(friendCookie);
  }
  
  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        You{((perspective === 'Friend') ? 'r friend, ' + Cookies.get("currentFriendName") + ' is': '\'re')} currently playing
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading && <div><ul>{userPlayingTrackData}</ul></div>}
    </div>
  )
}

export default GetSpotifyUserPlayingTrack;