import Cookies from 'js-cookie';

export function loggedIn() {
  if (Cookies.get('apiToken')) {
    return true
  } else {
    return false
  }
}

export async function logout() {
  // Delete the app token in our database.
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/logout', {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  // Delete all cookies for this domain.
  Object.keys(Cookies.get()).forEach(function(cookieName) {
    var neededAttributes = {
      // Here you pass the same attributes that were used when the cookie was created
      // and are required when removing the cookie
      domain: window.location.hostname,
    };
    Cookies.remove(cookieName, neededAttributes);
  });

  // Call Cognito API to logout.
  window.location.replace('https://' + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + '/logout?' +
    'response_type=code&' +
    'client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&' +
    'redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback');
}

export function connectedToSpotify() {
  if (Cookies.get('connectedToSpotify')) {
    return true
  } else {
    return false
  }
}

function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function ConnectSpotify() {
  // calculate state, store in a cookie
  var state = generateRandomString(16);
  Cookies.set('spotifyState',state, { expires: 7 })

  window.location.replace('https://accounts.spotify.com/authorize?response_type=code' +
  '&client_id=' + process.env.REACT_APP_SPOTIFY_CLIENT_ID +
  '&redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback-spotify' +
  '&scope=' + process.env.REACT_APP_SPOTIFY_APP_SCOPE + '&state=' + state);
}

export function connectedToAppleMusic() {
  if (Cookies.get('connectedToAppleMusic')) {
    return true
  } else {
    return false
  }
}

async function getAppleToken() {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-apple-token', {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const jsonData = await response.json()

  return jsonData
}

async function setAppleUserToken(token) {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/store-apple-music-user-token?' +
            'user-token=' + encodeURIComponent(token), {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return 'It worked'
}

export function ConnectAppleMusic() {
  getAppleToken().then(jsonData => {
    // console.log(jsonData)

    // eslint-disable-next-line no-undef
    MusicKit.configure(jsonData)

    // eslint-disable-next-line no-undef
    let music = MusicKit.getInstance()
    music.authorize().then(function(token) {
      // console.log(token)

      setAppleUserToken(token).then(() => {
        Cookies.set('connectedToAppleMusic', true, { expires: 7 })
        window.location.reload()
      })
    }).catch (e => {
      console.log ('Error:' + e )
    })
  })
}

export async function getSpotifyPlayerToken() {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-spotify-player-token', {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const jsonData = await response.json()
  return jsonData
}

export async function DisconnectSpotify() {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/delete-spotify-token', {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  Cookies.remove('connectedToSpotify')

  alert("Note: You should go into Spotify and remove access.");
  window.location.reload()
}

export async function getSpotifyTrackInfo(spotifyTrack) {
  const spotifyToken = await getSpotifyPlayerToken();
  const response = await fetch('https://api.spotify.com/v1/tracks/' + spotifyTrack, {
    headers: {
      "Authorization": "Bearer " + spotifyToken
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const jsonData = await response.json()
  Cookies.set('playerTrack', jsonData)
  return jsonData
}

export async function PurgeAppleMusicTracks() {
  const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/delete-apple-music-tracks', {
    headers: {
      "Authorization": "Bearer " + Cookies.get('apiToken')
    },
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  alert("Give it a few minutes.");
  window.location.reload()
}
